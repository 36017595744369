import React from 'react';

import Layout from '../../components/layout';
import Segment from '../../components/segment';
import Seo from '../../components/seo';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import SponsorGrid from '../../components/sections/sponsorGrid/sponsorGrid';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import Table from '../../components/sections/table/table';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      backgroundColor="#172D47!important"
      textColor="#06E55C!important"
      headerWitdh="header"
      headerStyle="flex--space-between"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Get Started" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="Blue Cross Blue Shield of Rhode Island"
        title="Treat hip, knee and back pain from home"
        titleClassName=""
        text="Online physical therapy with Joint Academy may be available to you at no additional cost as part of your health insurance."
        textClassName=""
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/o5ugtnx"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
      />
      <SponsorGrid
        title="We accept most major insurance plans"
        text="We’ve partnered with leading health insurance companies, including Medicare to offer treatment covered under your plan. Check your eligibility to get started."
        sponsors={[
          {
            alt: 'medicare',
            Filename: 'medicare.png',
          },
          {
            alt: 'blueshieldca',
            Filename: 'blueshieldca.png',
          },
          {
            alt: 'humana',
            Filename: 'humana2.png',
          },
          {
            alt: 'aetna',
            Filename: 'aetna.png',
          },
          {
            alt: 'blueshieldblue',
            Filename: 'BCBSRI.png',
          },
          {
            alt: 'united healthcare blue',
            Filename: 'united_healthcare_blue.png',
          },
        ]}
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/hufmon2"
        downloadclassname="btn btn--primary btn--md"
        downloadtext="Get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/o5ugtnx"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/o5ugtnx"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />
      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'cost_table_general@2x.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'cost_table_general_small@2x.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED TODAY"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/hufmon2"
        subText={[
          {
            text: '*Average estimated $30 copay for PT services for 1 billable service per month. Copay and final cost may be higher and is determined by your health plan based on your plans benefits. Deductible may apply. Contact Joint Academy or your health plan for further information regarding your coverage.',
            className: 'margin-bottom--xxxs',
          },
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
