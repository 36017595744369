import React from "react"
import Button from "../../Button"

import Image from "../../image"

const SponsorGrid = ({
  title,
  sponsors,
  text,
  downloaddataGoal,
  downloadhref,
  downloadclassname,
  downloadtext,
  promo,
}) => {
  return (
    <section data-theme="white">
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        <div className="text--component text--center margin-bottom--md">
          <h2 className="margin-bottom--sm">{title}</h2>
          <p>{text}</p>
        </div>

        <div className="flex flex--wrap flex--space-around flex--center-y flex--gap-md logo-grid">
          {sponsors &&
            sponsors.map(sponsor => (
              <div key={sponsor.alt} className={"logo--sm  "}>
                <Image
                  filename={sponsor.Filename}
                  alt={sponsor.alt}
                  style={{ width: "110px!important" }}
                />
              </div>
            ))}
        </div>
        <div className="text--center margin-top--md">
          <Button
            dataGoal={downloaddataGoal}
            href={downloadhref}
            classname={downloadclassname}
            text={downloadtext}
          />
          {promo && (
            <>
              <p className="margin-top--xs">
                No health insurance?
                <a
                  className="margin-left--xxs"
                  data-goal="Onboarding Viewed"
                  href="https://us-onboarding.jointacademy.com/"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  Try 7 days for free
                </a>
              </p>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

export default SponsorGrid
